import { useStorage } from '@vueuse/core';

import type { TSubmitSuccess } from '~/types/components/form';
import { AnalyticsEvents, AnyObject } from '~/types/analytics';

import { Address } from '~/restAPI/Address';
import { useApi } from '~/restAPI';
import { Verification } from '~/restAPI/Verification';

interface IPhoneVerifyDialogSettings {
  chapter: 'auth' | 'addToFavorites' | 'makeOrder' | 'productSceneContactSeller' | 'productSceneSubscribe' | 'publishProduct'
  phoneTitle?: string
  phoneText?: string
  secondDialog?: boolean
}

interface IForm {
  phone: string;
  globalError?: string
}

export function usePhoneVerification() {
  const { createApiInstance } = useApi();
  const verificationApi = createApiInstance(Verification);
  const addressApi = createApiInstance(Address);

  const {
    globalSettings,
    account,
  } = useAccountStore();

  const { PHONE_POPUP_WEB_EXPERIMENT, PHONE_VERIFICATION_WHATSAPP_FIRST } = useExperimentsStore();
  const { t } = useI18n();
  const { $addEvent } = useNuxtApp();

  const phoneVerifiedStorage = useStorage<boolean | undefined>('phoneVerifiedStorage', undefined);

  const isPhoneVerifyDialog = useState('isPhoneVerifyDialog', () => false);
  const phoneVerifyDialogSettings = useState<IPhoneVerifyDialogSettings | undefined>('phoneVerifyDialogSettings', () => undefined);

  const authSmsDialog = ref(false);
  const path = ref<'SMS' | 'WA'>(PHONE_VERIFICATION_WHATSAPP_FIRST.value ? 'WA' : 'SMS');
  const phoneNumber = ref(account.value.phone ?? '');
  const loading = ref(false);
  const $phoneInput = ref();
  const operation = ref<'VERIFY_PHONE_NUMBER' | 'AUTH_OR_REGISTER'>('VERIFY_PHONE_NUMBER');

  const phoneVerifyChapter = computed(() => phoneVerifyDialogSettings.value?.chapter);

  const needPhoneVerify = computed(() => !!account.value.id && account.value.isPhoneVerificationRequired);
  const phoneVerificationSettings = computed(() => globalSettings.value.phoneVerificationSettings);
  const phoneVerifyWebPopup = computed(() => {
    if (PHONE_POPUP_WEB_EXPERIMENT.value?.valueKey !== 'on') return undefined;
    return phoneVerificationSettings.value?.webPopup;
  });

  const { data: countries, execute: getCountries } = useLazyAsyncData(() => addressApi.getCountriesGet(
    { context: 'PHONE' },
    { format: 'json' },
  ).then((res) => res?.data?.data), { immediate: false });

  const onlyCountries = computed(() => countries.value?.map((c) => c.isoCodeAlpha2!).filter((c) => !!c));
  const formattedPhone = computed(() => $phoneInput.value?.phoneComp?.format('INTERNATIONAL') ?? '');

  const {
    data: verificationToken,
    execute: generateCode,
    pending: generateCodePending,
    error: generateCodeError,
  } = useLazyAsyncData(() => verificationApi.generateVerificationCodeUsingPost(
    {
      phoneNumber: phoneNumber.value,
      operation: operation.value,
      path: path.value,
    },
  ).then((res) => res?.data?.data), { immediate: false });

  generateCodePending.value = false;

  function openPhoneVerifyDialog(options?: IPhoneVerifyDialogSettings) {
    if (!needPhoneVerify.value) return;

    phoneVerifyDialogSettings.value = options;
    isPhoneVerifyDialog.value = true;
  }

  async function resendSmsCode(step?: 'whatsapp') {
    if (generateCodeError.value?.data?.message) {
      generateCodeError.value.data.message = '';
    }

    if (step === 'whatsapp') {
      path.value = 'WA';
    } else {
      path.value = 'SMS';
    }

    await generateCode();
  }

  async function submitSuccess({ actions }: TSubmitSuccess<any>, eventOptions?: AnyObject) {
    actions.setFieldError('globalError', undefined);

    loading.value = true;

    $addEvent(AnalyticsEvents.ClickEvent, eventOptions ?? {
      page: 'phone_number_input',
      chapter: phoneVerifyChapter.value,
      action: 'confirmation_code',
    });

    await generateCode();

    if (generateCodeError.value?.data?.message === 'Too Many Requests') {
      actions.setFieldError('globalError', t('auth.tooManyRequests'));
      loading.value = false;

      return;
    }

    if (generateCodeError.value?.data?.message) {
      actions.setFieldError('globalError', generateCodeError.value?.data?.message);
      loading.value = false;

      return;
    }

    const validationMessages: string[] = Object.values(generateCodeError.value?.data?.validationMessages ?? {});

    if (validationMessages.length) {
      actions.setFieldError('globalError', validationMessages[0]);
      loading.value = false;

      return;
    }

    if (!verificationToken.value) {
      actions.setFieldError('globalError', t('auth.wrongPhoneNumber'));
      loading.value = false;

      return;
    }

    authSmsDialog.value = true;
    loading.value = false;
  }

  return {
    path,
    needPhoneVerify,
    isPhoneVerifyDialog,
    phoneVerifyDialogSettings,
    phoneVerificationSettings,
    openPhoneVerifyDialog,
    phoneVerifyWebPopup,
    phoneVerifiedStorage,
    phoneVerifyChapter,

    authSmsDialog,
    phoneNumber,
    loading,
    $phoneInput,
    getCountries,
    onlyCountries,
    formattedPhone,
    resendSmsCode,
    submitSuccess,
    operation,
    verificationToken,
    generateCodePending,
    generateCodeError,
  };
}
