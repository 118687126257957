/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Api2ResponseOfBoolean,
  Api2ResponseOfString1,
  GenerationPayloadDto,
  VerificationPayloadDto,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Verification<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags verification-controller-api-v-2
   * @name VerifyPhoneNumberUsingPost
   * @summary verifyPhoneNumber
   * @request POST:/api/v2/verification/verifyPhoneNumber
   */
  verifyPhoneNumberUsingPost = (data: VerificationPayloadDto, params: RequestParams = {}) =>
    this.request<Api2ResponseOfBoolean, void>({
      path: `/api/v2/verification/verifyPhoneNumber`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags verification-controller-api-v-2
   * @name VerifyUsingPost
   * @summary verify
   * @request POST:/api/v2/verification/verify
   */
  verifyUsingPost = (data: VerificationPayloadDto, params: RequestParams = {}) =>
    this.request<Api2ResponseOfBoolean, void>({
      path: `/api/v2/verification/verify`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags verification-controller-api-v-2
   * @name GenerateVerificationCodeUsingPost
   * @summary generateVerificationCode
   * @request POST:/api/v2/verification/generate
   */
  generateVerificationCodeUsingPost = (data: GenerationPayloadDto, params: RequestParams = {}) =>
    this.request<Api2ResponseOfString1, void>({
      path: `/api/v2/verification/generate`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags verification-controller-api-v-2
   * @name VerifyPhoneNumberAndGetJwtUsingPost
   * @summary verifyPhoneNumberAndGetJWT
   * @request POST:/api/v2/verification/verifyPhoneNumberAndGetJWT
   */
  verifyPhoneNumberAndGetJwtUsingPost = (data: VerificationPayloadDto, params: RequestParams = {}) =>
    this.request<Api2ResponseOfString1, void>({
      path: `/api/v2/verification/verifyPhoneNumberAndGetJWT`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
